import React from "react";
import LayoutExamples from "../../../layouts/LayoutExamples";
import Seo from "../../../components/seo";
import { Row, Col, Divider } from "antd";
import ChartSchematic from "../../../components/chart/interactions/basic-schematic/Chart.js";
import ButtonsNavigation from "../../../components/ui/ButtonsNavigation.js";
import PeriprocedureelBeleidApixaban from "../../../client/lta-antistolling/periprocedureel-beleid-apixaban.json";

const ExampleMedicalPage3 = () => (
  <LayoutExamples>
    <Seo
      title="Medical example | Periprocedureel beleid - Apixaban"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Row
      justify="center"
      align="middle"
      style={{
        backgroundColor: "#f1f3f5",
        minHeight: "80vh",
      }}
    >
      <Col span={24}>
        <ChartSchematic
          entry={PeriprocedureelBeleidApixaban}
          firstNodeId="node1"
          language="nl"
        />
      </Col>
    </Row>
    <Divider dashed style={{ margin: "0" }} />
    <Row
      justify="center"
      align="middle"
      style={{ backgroundColor: "#f8f9fa", padding: "48px 0" }}
    >
      <Col>
        <ButtonsNavigation
          linkPrevious="Previous"
          linkPreviousTo="/examples/medical/example-tachycardia"
          linkNext="Next example"
          linkNextTo="/examples/medical/example-licht-traumatisch-hoofd-hersenletsel-kinderen-tot-16-jaar"
        />
      </Col>
    </Row>
  </LayoutExamples>
);

export default ExampleMedicalPage3;
